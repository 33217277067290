<template>
<layout-default>
  <section v-if="dataErrored">
      <p>We're sorry, we're not able to retrieve this information at the moment, please try back later</p>
    </section>
  <div v-else class="layout__contact position-relative overflow-hidden">
    <div class="container-fluid position-relative">
      <div class="row">
        <div class="col-12 offset-md-1 offset-lg-1 col-md-5 col-lg-4 mt-10">
          <h1 class>Contact</h1>
          <div class="w-100 py-lg-5"></div>
        </div>
      </div>
    </div>
    <div id="D" class="">
      <img src="@/assets/img/texture1.jpg" class="letter__img" :style="{ 'mask-image':maskimgD }" />
    </div>
    <div class="container-fluid">
      <div class="row py-5 my-4">
        <div class="col-12 offset-md-1 offset-lg-1 col-md-5 col-lg-5 pb-5 mb-5 pb-md-1 mb-md-1">
          <contact-form v-if="itLoads"></contact-form>
        </div>
        <div class="col-12 offset-md-1 offset-lg-2 col-md-4 col-lg-3 pt-5 mt-5 pt-md-1 mt-md-1">
          <contact-details v-if="itLoadsContact" :contactData="dataContact.acf"></contact-details>
        </div>
      </div>
    </div>
  </div>
  </layout-default>
</template>

<script>
import imgMaskD from "@/assets/img/d-invert.svg";
import ContactForm from "@/components/contact/contactForm";
import ContactDetails from "@/components/contact/contactDetails";
import LayoutDefault from '@/layouts/LayoutDefault';
export default {
  inject:['MyLoading'],
  components: {
    ContactForm,
    ContactDetails,
    LayoutDefault
  },
  data() {
    return {
      maskimgD: "url(" + imgMaskD + ")",
      pageData: Object,
      dataContact: Object,
      itLoads: false,
      itLoadsContact: false,
      dataLoading: true,
      dataErrored: false,
    };
  },
  head() {
    if(this.dataLoading == false) {
      const metaArray = [];
      this.pageData.yoast_meta.map(ele => {
        metaArray.push({
          hid: ele.name ? ele.name : ele.property,
          name: ele.name ? ele.name : ele.property,
          content: ele.content,
        });
      });

      return {
        title: this.dataLoading ? 'Contact' : this.pageData._yoast_wpseo_title,
        meta: metaArray,
      }
    }
  },
   methods: {
    fetchingData() {
      this.$axios
        .get(this.$axios.defaults.baseURL + "wp/v2/pages/34")
        .then(response => {
          this.pageData = response.data;
          this.itLoads = true;
           this.MyLoading.val = false;
        })
         .catch((error) => {
          console.log(error);
          this.dataErrored = true
        }).finally(() => this.dataLoading = false);
    },
    fetchingContact() {
      this.$axios
        .get(this.$axios.defaults.baseURL + "acf/v3/options/site-options-page")
        .then(response => {
          this.dataContact = response.data;
          this.itLoadsContact = true;
        })
        .catch(error => {
          console.log(error);
        });
    },
   },
   mounted() {
    this.fetchingData();
    this.fetchingContact();
  },
  created() {
    this.MyLoading.val = true;
  }

};
</script>

<style>
</style>