<template>
  <div class="contact-form">
    <b-form @submit="onSubmit" v-if="show">
      <b-form-group class="my-2 pb-4" id="input-group-2">
        <span class="line-top"></span>
        <label class for="input-1">Name</label>
        <b-form-input id="input-2" v-model="form.name" required placeholder="Enter name"></b-form-input>
        <span class="line-angle"></span>
      </b-form-group>

      <b-form-group class="my-2 pb-4" id="input-group-1">
        <span class="line-top"></span>
        <label class for="input-1">Email</label>
        <b-form-input
          id="input-1"
          v-model="form.email"
          type="email"
          required
          placeholder="Enter email"
        ></b-form-input>
        <span class="line-angle"></span>
      </b-form-group>

      <b-form-group class="my-2 pb-4" id="input-group-3">
        <span class="line-top"></span>
        <label class for="input-3">Message</label>
        <b-form-textarea
          id="input-3"
          v-model="form.message"
          placeholder="Enter message..."
          rows="3"
          max-rows="6"
        ></b-form-textarea>
        <span class="line-angle"></span>
      </b-form-group>

      <b-button type="submit" class="my-2">Send</b-button>
    </b-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        email: "",
        name: "",
        message: ""
      },
      show: true
    };
  },
  methods: {
    sendForm() {
      const formData = new FormData();
      formData.append("your-name", this.form.name);
      formData.append("your-email", this.form.email);
      formData.append("your-subject", 'Contac from Vue');
      formData.append("your-message", this.form.message);
      this.$axios
        .post("contact-form-7/v1/contact-forms/124/feedback", formData)
        .then(response => {
          console.log(response);
        })
        .catch(error => {
          console.log(error);
        });
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.sendForm();
      alert("Thanks for your message");
    }
  }
};
</script>

<style>
</style>