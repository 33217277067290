<template>
  <div>
      <div class="w-100 pb-4 text-white text-center text-lg-right">
          <p class="mb-0">Phone</p>
          <a :href="'tel:+'+contactData.phone" target="blanck" class="text-white no-link tx-lg">{{contactData.phone}}</a>
      </div>

      <div class="w-100 pb-4 text-white text-center text-lg-right">
          <p class="mb-0">Email</p>
          <a :href="'mailto:'+contactData.email" target="blanck" class="text-white no-link tx-lg">{{contactData.email}}</a>
      </div>

      <div class="w-100 pb-4 text-white text-center text-lg-right">
          <p class="mb-0">Social</p>
          <a :href="contactData.linkedin" target="blanck" class="text-white no-link tx-lg d-block" v-if="contactData.linkedin">Linkedin</a>
          <a :href="contactData.instagram" target="blanck" class="text-white no-link tx-lg d-block" v-if="contactData.instagram">Instagram</a>
      </div>
  </div>
</template>

<script>
export default {
    props:{
        contactData: Object
    },
    data(){
        return{
        }
    }
}
</script>

<style>

</style>